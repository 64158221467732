export default {
    'PRODUCT_UNIT_公斤': '公斤',
    'PRODUCT_UNIT_克': '克',
    'PRODUCT_UNIT_斤': '斤',
    'PRODUCT_UNIT_兩': '兩',
    'PRODUCT_UNIT_把': '把',
    'PRODUCT_UNIT_盒': '盒',
    'PRODUCT_UNIT_包': '包',
    'PRODUCT_UNIT_顆': '顆',
    'PRODUCT_UNIT_袋': '袋',
    'PRODUCT_UNIT_條': '條',
    'PRODUCT_UNIT_尾': '尾',
    'PRODUCT_UNIT_片': '片',
    'PRODUCT_UNIT_隻': '隻',
    'PRODUCT_UNIT_箱': '箱',
    'PRODUCT_UNIT_份': '份',
    'PRODUCT_UNIT_件': '件',
    'PRODUCT_UNIT_瓶': '瓶',
    'PRODUCT_UNIT_桶': '桶',
    'PRODUCT_UNIT_罐': '罐',
    'PRODUCT_UNIT_台': '台',
    'PRODUCT_UNIT_組': '組',
}