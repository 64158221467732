export default {
  methods: {
    /**
     * @param {*} configs discount_config
     * @param {*} price 原價
     * @returns boolean
     */
     isValidDiscount(configs, price) {
      if (!configs) return false;

      const range = configs.map((item) => {
        return [+item.min, +item.max]
      }).sort()

      // 檢查是否每項的min都小於max
      if (!this.validRange(range)) {
        this.$snotify.error(null, '每項的最小值不可 >= 最大值')
        return false
      }
      // 範圍重疊就錯
      if (this.isRangeCover(range)) {
        this.$snotify.error(null, '範圍重疊')
        return false
      }
      // 優惠價格不能高於單價
      if (!this.isDiscountValid(configs, price)) {
        this.$snotify.error(null, '優惠價格不能高於單價')
        return false
      }

      return true
    },
    isDiscountValid(configs, price) {
      const res = configs.some(item => {
        return item.price >= price
      })
      return !res
    },
    validRange(range = []) {
      const inValid = range.some((item) => {
        return item[0] >= item[1]
      })

      return !inValid
    },
    isRangeCover(range = []) {
      let min = 0,
        max = 0;
      let isCover = false;

      for (let i = 0; i < range.length; i++) {
        const target = range[i];
        const hasNext = i < range.length - 1;

        if (i == 0) {
          min = target[0];
          max = target[1];
          continue;
        }

        // 檢查是否重疊
        if (target[0] < min && target[1] < min) {
          min = target[0];
        } else if (target[0] > max && target[1] > max) {
          max = target[1];
        } else {
          isCover = true;
        }

        if (isCover) return true;
        if (!hasNext) return isCover;
      }
    }
  }
}