<template>
  <eagle-form :form-key="formKey"></eagle-form>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './productFormConfig'
import discountMixins from "@/mixins/discount.js"
export default {
  mixins: [formMixin, discountMixins],
  data: () => ({
    formKey: 'product-form',
    meta: {},
    isSetted : false
  }),
  mounted() {
    this.meta.loadCategories = this.loadCategories
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([
        this.loadCategories(),
        this.getAllProviders()
      ])
    },
    async loadCategories() {
      let result = null
      try {
        result = await this.$api.collection.productCategoryApi.flatTree()
      } catch (error) {
        console.warn(error)
      }

      this.meta.categories = result.nodes
      return result
    },
    async getAllProviders() {
      try {
        let providers = await this.$api.collection.providerApi.index({pager: 0})
        this.meta.providers = providers
      } catch (error) {
        console.warn(error)
      }
    },
    validateBeforeSave() {
      const configs = this.formData.discount_config
      if(!configs) return;

      const isValid = this.isValidDiscount(configs, this.formData.price)
      if(!isValid) return true
    },
    async readApi(target) {
      const result = await this.$api.collection.productApi.read(target)
      return this.getComputedReadData(result)
    },
    getComputedReadData(product) {
      if(Array.isArray(product.categories)) product.categories = product.categories[0]
      else product.categories = null
      return product
    },
    async createApi(formData) {
      return await this.$api.collection.productApi.create(formData)
    },
    async updateApi(target, formData) {
      if(!formData.categories) formData.categories = []
      else formData.categories = [formData.categories]
      const result = await this.$api.collection.productApi.update(target, formData)
      return this.getComputedReadData(result)
    },
    async deleteApi(target) {
      return await this.$api.collection.productApi.delete(target)
    },
    getFormConfig() {
      return formConfig
    },
    // async formSaveFinished() {
    //   儲存完強制更新
    //   await this.formActions.initForm()
    // },
    isTheSame() {
      if(!this.formData) return ;
      const isSame = ( !this.shipping_unit && !this.calculate_unit) ?
        false :
        (this.shipping_unit == this.calculate_unit)

      if(isSame && !this.isSetted) {
        this.isSetted = true;
        const formData = this.$eagleLodash.cloneDeep(this.formData)
        formData.per_shipping_count = 1
        this.$store.dispatch(`form/${this.formKey}/setData`, formData)
      } 
      else {
          if(!isSame) {
            this.isSetted = false ;
          }
      }
    }
  },
  computed: {
    frontendDomain() {
      return this.$store.getters['base/eagleEnv'].frontendDomain
    },
    productCategoryMaxDepth() {
      return this.$store.getters['base/productCategoryMaxDepth']
    },
    shipping_unit() {
      if(!this.formData) return null
      return this.formData.shipping_unit
    },
    calculate_unit() {
      if(!this.formData) return null
      return this.formData.calculate_unit
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
