import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import unitConstants from '../config/unitConstants'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
      price: 1,
      per_shipping_count: 1,
    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    const unitList = Object.keys(unitConstants).map((key: any) => {
      // @ts-ignore
      const data = unitConstants[key]
      return {
        text: data,
        value: data
      }
    })

    return {
      from: () => [
        { label: 'page.product', route: { name: 'product' } },
      ],
      listRoute: () => ({ name: 'product' }),
      afterCreateRoute: result => ({
        name: 'product-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.product',
      defaultData: this._defaultData,
      dataAction: {
        delete: false,
      },
      metaAction: {},
      blocks: [
        {
          title: 'form_block.base',
          data: {
            photos: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'product',
              // photoManager: true,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({ originalData, data, photoListEqual }: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
            provider_id: {
              required: true,
              label: 'belong-to-provider',
              type: 'selection',
              options: (formInfo: any) => {
                if (!formInfo.formMeta) return []
                return formInfo.formMeta.providers.map((provider: any) => {
                  return { text: provider.name, value: provider.id }
                })
              }
            },
            name: {
              required: true,
              label: 'data.name',
              type: 'text',
              maxlength: 100,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            categories: {
              label: 'data.categories',
              type: 'selection',
              optionMeta: item => ({ depth: item.depth }),
              // @ts-ignore
              // itemDisabled: (item, formMeta) => item.depth < this.vm.productCategoryMaxDepth,
              optionText: (itemName, item: AnyObject) => this.vm?.$helper.nestedSetNodeNamePrefix(item.name, item.depth),
              refreshCallback: async (formInfo: FormInfoInterface) => {
                if (!formInfo.formMeta) return
                const result = await formInfo.formMeta.loadCategories()
                this.vm?.$store.commit(`form/${this.formKey}/setMetaProperty`, {
                  key: 'categories',
                  value: !Array.isArray(result.nodes) ? [] : result.nodes,
                })
              },
              optionTextKey: 'name',
              optionValueKey: 'id',
              returnObject: true,
              options: (formInfo: FormInfoInterface) => {
                if (!formInfo.formMeta) return []
                if (!Array.isArray(formInfo.formMeta.categories)) return []
                return formInfo.formMeta.categories
              },
              hasChanged: ({ originalData, data, bothEmpty, arrayPropertyDiff }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            description: {
              label: 'product.data.description',
              type: 'textarea',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            shipping_unit: {
              label: 'product.data.shipping_unit',
              type: 'selection',
              options: unitList,
              clearable: true,
              hasChanged({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) {
                // @ts-ignore
                vm.isTheSame()
                
                if (bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            calculate_unit: {
              label: 'product.data.calculate_unit',
              type: 'selection',
              options: unitList,
              clearable: true,
              hasChanged({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) {
                // @ts-ignore
                vm.isTheSame()
                
                if (bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            per_shipping_count: {
              label: 'product.data.per_shipping_count',
              type: 'text',
              inputType: 'number',
              disabled: (formInfo) => {
                if(!formInfo) return false
                if(!formInfo.formData) return false
                const {calculate_unit,shipping_unit } = formInfo.formData
                return calculate_unit == shipping_unit
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            price: {
              label: 'product.data.price',
              type: 'text',
              inputType: 'number',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            daily_alert: {
              label: 'product.data.daily_alert',
              type: 'switch',
              hasChanged: ({ originalData, data }: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            status: {
              label: 'data.status',
              type: 'switch',
              hasChanged: ({ originalData, data }: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
          },
        },

        {
          title: 'product.form_block.discount_config',
          data: {
            discount_config: {
              component: () => import('@/modules/product/components/discountConfig.vue'),
              hasChanged: ({ originalData, data }: FormDataChangeHelperInterface) => {
                return !window.eagleLodash.isEqual(originalData, data)
              },
            },
          }
        },

        // 商品主資料設定
        // {
        //   title: 'product.form_block.setup',
        //   data: {
        //     // 商品規格
        //     instance_config: {
        //       label: 'product.data.spec',
        //       grid: { xl: 12, lg: 12, md: 12 },
        //       create: (formInfo: FormInfoInterface) => {
        //         if(!formInfo.formData) return false
        //         return !!formInfo.formData.instance_config
        //       },
        //       component: () => import('./productForm/instanceConfig.vue'),
        //     },
        //     instances: {
        //       grid: { xl: 12, lg: 12, md: 12 },
        //       component: () => import('./productForm/instances.vue'),
        //       hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
        //         if(bothEmpty(originalData, data)) return false
        //         return window.eagleLodash.isEqual(originalData, data) === false
        //       },
        //     },
        //   },
        // },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }

}

export default new formConfig()
